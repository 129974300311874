import React from "react"
import { useStaticQuery, graphql } from "gatsby";
import LandingCarousel from "../../components/landing-carousel-slick/landing-carousel";
import LandingCarouselItem from "../../components/landing-carousel-slick/landing-carousel-item";
import * as styles from './landing-section.module.scss';
import FStyleNeon from "../../svg/f-style-neon.svg";
import LogotypeSVG from "../../svg/beefteka_logo_white.svg";
import { Col, Row } from "react-bootstrap";

export default function LandingSection(props) {

  const data = useStaticQuery(graphql`{
  image1: file(relativePath: {eq: "beefteka/hero.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 99, layout: FULL_WIDTH)
    }
  }
}
`)

  return <>
    <span id="landing-section"></span>
    <div className={styles.landingCarouselWrapper}>

      <LandingCarousel>
        <LandingCarouselItem
          imgFluid={data.image1.childImageSharp.gatsbyImageData}
          imgAlt={"restauracja"}
        />
      </LandingCarousel>
      <div className={styles.topShadow} />
      <div className={styles.heroTextWrapper}>
        <div className={styles.heroTextInner}>
          <div className={styles.heroTitle}>Poczuj smak pasji!</div>
          <div className={styles.heroSubtitle}>Restauracja Beefteka - nowe miejsce spotkań na mapie Koła</div>
        </div>
      </div>
      <div className={styles.bottomShadow}>
        <Row className={styles.bottomRow}>
          <Col lg={1} md={0} xs={0}></Col>
          <Col className={styles.bottomRowColumn} lg={{ span: 3 }} md={{ span: 4 }} xs={12}>
            <div className={styles.ctaHeader}>Zadzwoń do nas</div>
            <a className={styles.ctaInfo} href="tel:+48 535 76 76 76">+48 535 76 76 76</a>
          </Col>
          <Col className={styles.bottomRowColumn} md={4} xs={12}>
            <div className={styles.ctaHeader}>Odwiedź nas</div>
            <a className={styles.ctaInfo} href="https://goo.gl/maps/Qq7qVwT6wXh4cRQK9" target="_blank" rel="noreferrer">ul. Sienkiewicza 35, Koło</a>
          </Col>
          <Col className={styles.bottomRowColumn} lg={3} md={4} xs={12}>
            <div className={styles.ctaHeader}>Napisz do nas</div>
            <a className={styles.ctaInfo} href="mailto:kontakt@beefteka.pl">kontakt@beefteka.pl</a>
          </Col>
          <Col lg={1} md={0} xs={0}></Col>
        </Row>
      </div>
    </div>
  </>;
}

import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Col, Row } from "react-bootstrap";
import * as styles from "./dish-of-the-day-section.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";
import NewsAndEvents from "../../components/news-and-events/news-and-events";
import HorizontalLineSeparatorMobile
    from "../../components/horizontal-line-separator-mobile/horizontal-line-separator-mobile";
import VerticalSpacingSmallRow from "../../components/vertical-spacing-small/vertical-spacing-small";
import { NewsId } from "../../components/layout/layout";

export default function DishOfTheDaySection() {
    const data = useStaticQuery(graphql`{
        menuApi {
            news {
                newsTitle
                newsDescription
                newsUrl
                eventTitle
                eventDescription
                eventUrl,
                imageUrl,
                localImage {
                    childImageSharp {
                        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
                    }                    
                }
            }
        }
    }`);

    return <>
        <span id={NewsId}></span>
        <div>
            <Row className="no-gutters align-items-center">
                <Col md={{span: 12, order: 1}}>
                    <GatsbyImage
                        image={data.menuApi.news.localImage.childImageSharp.gatsbyImageData}
                        alt={"Danie dnia"}
                        objectFit="cover"
                        objectPosition="25% 75%"
                        className={styles.photoWrapper}
                    />
                </Col>
                <Col md={{span: 8, offset: 2, order: 1}}
                     xs={{span: 10, offset: 1, order: 1}}
                     className={styles.rectangleWrapper}>
                    <NewsAndEvents data={data.menuApi.news}/>
                </Col>
            </Row>
            <VerticalSpacingSmallRow/>
            <HorizontalLineSeparatorMobile/>
        </div>
    </>
}
import React from "react"
import * as styles from "./landing-carousel.module.scss"
import Slider from "react-slick";

class LandingCarousel extends React.Component {
  render() {
    const settings = {
      dots: true,
      // infinite: true,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <></>,
      prevArrow: <></>,

      autoplay: true,
      autoplaySpeed: 6800,
      pauseOnHover: false,
      pauseOnFocus: true,
      pauseOnDotsHover: true
    };

    return <>
    <div
      className={styles.wrapper}
    >
      <Slider {...settings}>
        {this.props.children}
      </Slider>
    </div>
  </>
  }
}

export default LandingCarousel;

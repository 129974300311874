import React from "react"
import * as styles from "./landing-carousel.module.scss"
import { Container, Row, Col } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";

export default function LandingCarouselItem(props) {
  return <>
    <div className={styles.carouselItem}>
      <div className={styles.backgroundImageOuterWrapper}>
        <GatsbyImage
          image={props.imgFluid}
          alt={props.imgAlt}
          loading="eager"
          className={styles.backgroundImageInnerWrapper} />
      </div>
      <div className={styles.textWrapper}>
        <Container fluid >
          <Row>
            <Col xs={12} md={{ span: 8, offset: 2 }}>
              {props.text}
            </Col>
          </Row>
        </Container>
      </div>

    </div>
  </>;
}

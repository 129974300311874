import React from "react"
import * as styles from "./we-are-section.module.scss"
import { Container, Row, Col } from "react-bootstrap";
import VerticalSpacingRow from "../../components/vertical-spacing/vertical-spacing";
import VerticalLineRow from "../../components/vertical-line/vertical-line";
import LeftHeaderTextRow from "../../components/left-header-text/left-header-text";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import { MeetUsId } from "../../components/layout/layout";
import VerticalSpacingSmallRow from "../../components/vertical-spacing-small/vertical-spacing-small";
import HorizontalLineSeparatorMobile from "../../components/horizontal-line-separator-mobile/horizontal-line-separator-mobile";

export default function WeAreSection(props) {

  const data = useStaticQuery(graphql`{
    imageDesktop: file(relativePath: {eq: "beefteka/passion.jpg"}) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
  }
  `);

  return <>
    <span id={MeetUsId}></span>
    <Container fluid>
      <VerticalSpacingRow />
      <Row className="no-gutters align-items-center">
        <Col md={{ span: 4, offset: 1, order: 2 }} xs={{ span: 12, order: 2 }} className={styles.photoColumn}>
          <GatsbyImage
            image={data.imageDesktop.childImageSharp.gatsbyImageData}
            alt={"Stek z antrykotu"}
            objectFit="contain"
            className={styles.photoWrapper} />
        </Col>
        <Col md={{ span: 5, offset: 1, order: 1 }} xs={{ span: 12, order: 1 }}>
          <LeftHeaderTextRow
            sectionName="O nas"
            header="Z pasji do jedzenia"
            texts={[
            `To właśnie pasja do jedzenia powoduje, że nasze potrawy zapadają w pamięć na długi czas. Wybierz się w kulinarną podróż z daniami przygotowywanymi przez wybitnych kucharzy i miło spędź czas w niepowtarzalnej atmosferze w restauracji Beefteka. Uwielbiamy muzykę na żywo, a oferowane przez nas piwa są przygotowywane na miejscu pod marką Browar Koło.`
            ]}
            buttonText="Zobacz nasze menu"
            buttonTarget="/menu/"
          />
        </Col>
      </Row>
      <div className={styles.hideOnDesktop}>
        <VerticalSpacingRow/>
      </div>
      <HorizontalLineSeparatorMobile/>

    </Container>
  </>;


}
import React from "react"
import * as styles from "./booking-section.module.scss"
import { Container, Row, Col } from "react-bootstrap";
import VerticalSpacingRow from "../../components/vertical-spacing/vertical-spacing";
import VerticalLineRow from "../../components/vertical-line/vertical-line";
import LeftHeaderTextRow from "../../components/left-header-text/left-header-text";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import { MeetUsId } from "../../components/layout/layout";
import VerticalSpacingSmallRow from "../../components/vertical-spacing-small/vertical-spacing-small";
import HorizontalLineSeparatorMobile from "../../components/horizontal-line-separator-mobile/horizontal-line-separator-mobile";

export default function BookingSection(props) {

  const data = useStaticQuery(graphql`{
    imageOne: file(relativePath: {eq: "beefteka/booking-1.jpg"}) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    },
    imageTwo: file(relativePath: {eq: "beefteka/booking-2.jpg"}) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
  }
  `);

  return <>
    <span id={MeetUsId}></span>
    <Container fluid>
      {/* <VerticalSpacingRow /> */}
      {/* <VerticalLineRow /> */}
      <VerticalSpacingRow />
      <Row className="align-items-center">
        <Col md={{ span: 5, offset: 1, order: 1 }} xs={{ span: 12, order: 1 }} className={styles.photoColumn}>
          <GatsbyImage
            image={data.imageOne.childImageSharp.gatsbyImageData}
            alt={"Jedzenie i napoje"}
            objectFit="contain"
            className={styles.photoWrapper} />
        </Col>
        <Col md={{ span: 5, order: 2 }} xs={{ span: 12, order: 3 }} className={styles.photoColumn}>
          <GatsbyImage
            image={data.imageTwo.childImageSharp.gatsbyImageData}
            alt={"Goście restauracji"}
            objectFit="contain"
            className={styles.photoWrapper} />
          <VerticalSpacingSmallRow/>
        </Col>
        <Col className={styles.descriptionContainer} md={{ span: 10, offset: 1, order: 3 }} xs={{ span: 10, offset: 1, order: 3 }}>
          <LeftHeaderTextRow
            sectionName="rezerwacje"
            header="Idealne miejsce na spotkania w bliskim gronie"
            texts={[
            `Niepowtarzalny klimat i przyjazna atmosfera Beefteki zachęca do spędzania tutaj czasu z najbliższymi. Uważmy, że nasze dania przygotowane z najwyższą dbałością, nastrojowa muzyka i unikalny wystrój pozwolą przeżyć niezapomniany wieczór, dlatego zachęcamy do organizowania imprez okolicznościowych w naszej restauracji.`,
            `Nasza antresola jest unikalnym miejscem na spotkanie biznesowe lub wieczór ze znajomymi.`
            ]}
            buttonText="Skontaktuj się z nami"
            buttonTarget="/menu/"
          />
        </Col>
      </Row>
    </Container>
    <HorizontalLineSeparatorMobile/>
    <VerticalSpacingSmallRow/>


  </>;


}
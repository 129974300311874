import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";
import * as styles from "./cook.module.scss"
import {Row, Col, Container} from "react-bootstrap";

export default function CookLeft(props) {
    return <Container fluid>
        <Row className="align-items-center">
            <Col md={{span: 4, offset: 1, order: 1}} xs={{span: 12, order: 1}}>
                <GatsbyImage alt={props.header} image={props.image}/>
            </Col>
            <Col md={{span: 5, offset: 1, order: 2}} xs={{span: 12, order: 2}}>
                <div className={styles.header}>{props.header}</div>
                {props.description.map((d, i) => <p key={i} className={styles.text}>{d}</p>)}
            </Col>
        </Row>
    </Container>
}
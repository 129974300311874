import React from "react";
import * as styles from "./news-and-events.module.scss";
import { Col } from "react-bootstrap";

export default function NewsAndEvents(props) {
    const data = props.data;
    return <div className={styles.rectangleWrapper}>
        {(data.newsTitle || data.newsDescription) && <div className={styles.listItems}>
            <Col lg={{span: 8, offset: 2, order: 1}} md={{span: 10, offset: 1, order: 1}}>
                <div className={styles.header}>
                    Nowości
                </div>

                { data.newsUrl ?
                    <a href={data.newsUrl} target="_blank" className={styles.title}>{data.newsTitle}</a>
                    :
                    <span className={styles.title}>{data.newsTitle}</span>
                }

                <div className={styles.text}>
                    {data.newsDescription}
                </div>
            </Col>
        </div>}

        
        {((data.newsTitle || data.newsDescription) && (data.eventTitle || data.eventDescription)) && <div className={styles.horizontalLine}/>}

        {(data.eventTitle || data.eventDescription) && <>

        <div className={styles.listItems}>
            <Col lg={{span: 8, offset: 2, order: 1}} md={{span: 10, offset: 1, order: 1}}>
                <div className={styles.header}>
                    Wydarzenia
                </div>

                { data.eventUrl ?
                    <a href={data.eventUrl} target="_blank" className={styles.title}>{data.eventTitle}</a>
                    :
                    <span className={styles.title}>{data.eventTitle}</span>
                }

                <div className={styles.text}>
                    {data.eventDescription}
                </div>
            </Col>
        </div></>}
    </div>
}

import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import CookLeft from "../../components/cook/cook-left";
import VerticalSpacingRow from "../../components/vertical-spacing/vertical-spacing";
import CookRight from "../../components/cook/cook-right";
import HorizontalLineSeparatorMobile from "../../components/horizontal-line-separator-mobile/horizontal-line-separator-mobile";
import VerticalSpacingSmallRow from "../../components/vertical-spacing-small/vertical-spacing-small";
import * as styles from "./cooks-section.module.scss"
import HorizontalLineSeparatorDesktop
    from "../../components/horizontal-line-separator-desktop/horizontal-line-separator-desktop";

export default function CooksSection(props) {
    const data = useStaticQuery(graphql`{
  allFile(
    filter: {relativeDirectory: {eq: "beefteka/cooks-images"}}
    sort: {fields: [name], order: ASC}
    limit: 2){
    edges {
      node {
        name
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  }
}
`);

    return <div>
        <VerticalSpacingSmallRow/>
        <CookLeft
            image={data.allFile.edges[0].node.childImageSharp.gatsbyImageData}
            header="Jakub Bęczkowski"
            description={[ "Szef Kuchni naszej restauracji. Zdobywał doświadczenie gastronomiczne w Irlandii, a po powrocie do Polski pracował w prestiżowych restauracjach. Od 7 lat zajmuje się kuchnią wegetariańską i wegańską, stawiając na nieszablonowe pomysły, które wprowadzane są również w Beeftece. Fani dań mięsnych również skorzystają z ogromnego doświadczenia naszego Szefa - przyjdź i przekonaj się sam!"]}
        />
        <VerticalSpacingSmallRow/>
        <HorizontalLineSeparatorMobile/>
        <VerticalSpacingSmallRow/>

        <CookRight
            image={data.allFile.edges[1].node.childImageSharp.gatsbyImageData}
            header="Marcin Czubak"
            description={["Patron szef, który nas wspiera przy pracy nad nowymi potrawami i często gości w naszej kuchni, tworząc zgrany duet z Kubą. Finalista 3. edycji Top Chef, twórca projektów kulinarnych i restaurator. Jego głowa jest zawsze pełna pomysłów, a jego zaangażowanie i pasja pozwalają nam przygotowywać dania na poziomie niespotykanym dotąd w regionie."]}
        />
        <VerticalSpacingSmallRow/>
        <HorizontalLineSeparatorMobile/>
        <HorizontalLineSeparatorDesktop/>
    </div>;
}
import React from "react";
import Layout from "../components/layout/layout";
import WeAreSection from "../page-sections/we-are-section/we-are-section";
import LandingSection from "../page-sections/landing-section/landing-section";
import GallerySection from "../page-sections/gallery-section/gallery-section";
import BookingSection from "../page-sections/booking-section/booking-section";
import CooksSection from "../page-sections/cooks-section/cooks-section";
import DishOfTheDaySection from "../page-sections/dish-of-the-day-section/dish-of-the-day-section";
import DessertSection from "../page-sections/dessert-section/dessert-section";


export default function Home() {
  return <Layout>
      <LandingSection />
      <WeAreSection />
      <BookingSection />
      <DishOfTheDaySection/>
      <CooksSection />
      <GallerySection />
      <DessertSection />
  </Layout>
}